const DynamicName = process.env.VUE_APP_COMPANY_NAME;
const DRA_Train_321 = {
	'SITE_NAME': 'Delware Restaurant Association',
	'FOOTER_NOTICE': 'Train 321, LLC',
	'SITE_URL': 'https://dra.train321.com',
	'LOGO': 'dra_logo.png',
	'INFO_EMAIL': 'dra@train321.com',
	'SUPPORT_EMAIL': 'support@train321.com'
}
let Dynamic = {};
switch (DynamicName) {
	case "DRA_Train_321":
		Dynamic[DynamicName] = DRA_Train_321;
		break;
	default:
		Dynamic["DRA_Train_321"] = DRA_Train_321;
}
let DName = Dynamic[DynamicName];
export {DName as Dynamic}  ;
